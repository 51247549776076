/* eslint-disable prettier/prettier */
import React, { Fragment } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import cn from 'classnames';

import Trans from 'src/components/shared/Trans/Trans';
import { shouldDisplayError } from 'src/utils/shouldDisplayError';
import { shouldDisplayCheckmark } from 'src/utils/shouldDisplayCheckmark';

import ErrorMessage from 'src/components/shared/ErrorMessage/ErrorMessage';
import Label from 'src/components/shared/Label/Label';
import { isRequired } from 'src/utils/validators';

import styles from './TextEditorField.scss';

const TextEditorField = ({ label, name, className, required, ...props }) => {

  return (
    <div className={cn(className, styles.field)}>
      <Field {...{ name }} validate={required ? isRequired : null}>
      {({ input, meta: { valid, error, touched, dirty, submitError, dirtySinceLastSubmit } }) => (
        <Fragment>
          <Label
            htmlFor={name}
            required={required}
            shouldDisplayCheckmark={shouldDisplayCheckmark({
              required,
              valid,
              value: input.value,
              submitError,
              dirtySinceLastSubmit,
            })}
          >
            {label}
          </Label>

          <Editor
            apiKey="92f37fz66uwmrdjk1kppee2ahqfcu00xj5uf27562erodhqr"
            referrerPolicy="origin"
            name={input.name}
            value={input.value}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
            onEditorChange={ (content) => props.setFormValue(name, content) }
            id={name}
            className={cn(styles.textareaControl, {
              [styles.error]: shouldDisplayError({
                valid,
                touched,
                dirty,
                dirtySinceLastSubmit,
                submitError,
              }),
            })}
            init={{
              height: 300,
              menubar: true,
              branding: false,
              document_base_url: window.location.origin,
              referrer_policy: 'origin',
              content_security_policy: "default-src 'self'",
              plugins: [
                'autolink',
                'link',
                'lists',
                'visualblocks',
                'table',
                'code'
              ],
              toolbar: 'undo redo | headings | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link | table | code',
              toolbar_groups: {
                headings: {
                  icon: 'format',
                  tooltip: 'Headings',
                  items: 'h1 h2 h3 h4 h5 h6'
                }
              },
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          /> 

          {shouldDisplayError({ valid, touched, dirty, dirtySinceLastSubmit, submitError }) && (
            <ErrorMessage>
              <Trans noMd>{error || submitError}</Trans>
            </ErrorMessage>
          )}
        </Fragment>
      )}
    </Field>
      
    <span className="version" hidden></span>
    </div>
    
  )
}

TextEditorField.defaultProps = {
  required: false,
  label: 'Label',
  className: '',
};

TextEditorField.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TextEditorField;
